@import "../../variables";

.file-storage-container {
  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid $border;
    margin-bottom: 50px;
  }

  .page-content {
    position: relative;
    width: 90%;
    max-width: 1400px;
    margin: auto;

    .root-title {
      font-family: $heading;
      letter-spacing: 2px;
      font-size: 20px;
      color: $font-color;
    }

    .files-button {
      color: $font-color;
      border: 2px solid $border;
      text-align: center;
      width: 110px;
      height: 30px;
      font-size: 16px;
      line-height: 25px;

      &:hover {
        color: $blue;
      }
    }

    .upload-btn-container {
      padding: 0;
    }

    .folder-path {
      padding-top: 25px;
    }

    .sort-container {
      position: absolute;
      right: 0;
      top: 60px;
      color: #c0d0d0;
      width: 140px;

      .label {
        padding-right: 8px;
      }

      .selection {
        font-weight: bold;
        cursor: pointer;

        .down-arrow {
          position: relative;
          color: #c0d0d0;
          top: 2px;
        }

        .down-arrow:hover {
          color: $white;
        }
      }

      .selection:hover {
        color: $white;

        .down-arrow {
          color: $white;
        }
      }

      .options {
        position: relative;
        left: 50px;
        top: 2px;
        background-color: $darkcyan;
        z-index: 10;
        list-style: none;
        width: 80px;
        border: 1px solid $darkgrey;

        .option {
          cursor: pointer;
          position: relative;
          right: 40px;
          line-height: 30px;
          vertical-align: middle;
          margin: auto;
          width: 80px;
          padding-left: 10px;
        }

        .option:hover {
          color: $white;
        }
      }
    }


    .clickable {
      display: flex;
      width: max-content;
      cursor: pointer;
    }


    .sort-icon {
      position: relative;
      bottom: 1px;
      left: 4px;
    }


    .file-icon-cell {
      position: relative;
    }

    .file-icon {
      position: absolute;
      right: 9px;
      top: 0;
    }

    .icon-container {
      position: relative;
      padding-left: 30px;

      .dl-icon {
        margin-right: 7px;
      }

      .download-btn {
        width: 17px;
      }

      .delete-btn {
        width: 12px;
      }
    }

    .confirm-cell {
      height: 5px;
      width: 200px;
      color: $font-color;

      .confirm {
        padding-right: 0;

        :hover {
          color: $grey;
        }
      }

      .cancel {
        padding-left: 5px;

        :hover {
          color: $grey;
        }
      }

      .color {
        color: $grey;
      }
    }
  }

  .table-column {
    &.icon {
      width: 5%;
    }

    &.name {
      width: 25%;
    }

    &.date {
      width: 15%;
    }

    &.creator_email {
      width: 15%;
    }

    &.tags {
      width: 20%;
    }

    &.buttons {
      width: 8%;
    }
  }

  .date-minutes {
    color: $font-color;
    padding-left: 5px;
  }

  .tag-filter-container {
    position: absolute;
    right: 0;
    top: 57px;
  }

  .tag-filter {
    cursor: pointer;
    align-items: center;
    margin: 3px;
    margin-right: 5px;
    padding: 5px 7px 5px 7px;
    border: 1px solid $grey;
    border-radius: 5px;
    background-color: $grey;
    white-space: nowrap;
    color: $font-color;
    font-size: 15px;

    button {
      position: relative;
      bottom: 1px;
      padding: 6px;
      border: none;
      background-color: unset;
      cursor: pointer;
      color: $font-color;
    }
  }

  .report-button-container {
    position: relative;
    bottom: 22px;
    float: right;
    height: 20px;

    .loader-btn {
      width: 150px;
    }

    .error-msg-container {
      color: $red;
      font-size: 15px;

      .err-icon {
        position: relative;
        fill: $red;
        margin-right: 3px;
        top: 2px;
      }
    }

    .success-msg-container {
      color: $font-color;
      font-size: 15px;
    }

    .relative-loader {
      position: relative;
      margin-right: 50px;
      bottom: 12px;
    }
  }
}


