@import "../../variables";

.network-metrics-content {
  width: 100%;
  .table-title {
    color: $lightgrey;
    white-space: nowrap;
    letter-spacing: 1.5px;
    font-family: $heading;
  }

  .table-container {
    width: 85%;
    .data-table-row {
      line-height: 35px;
    }
    .data-table-cell {
      color: $grey;
    }
  }

  .amount {
    font-size: 25px;
    padding-left: 10px;
    font-weight: 100;
    text-align: left;

    .arrow {
      margin: 0 0 5px 5px;
      height: 18px;
    }

    &.yellow {
      color: $yellow;
      &.true {
        background-image: linear-gradient(#ffe133, transparent);
      }
    }

    &.green {
      color: #d0f31a;
      &.true {
        background-image: linear-gradient(#d0f31a, transparent);
      }
    }
  }
}
