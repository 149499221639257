@import '../../variables';

.table-row {
  display: flex;
  padding: 0 50px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: -1px;
  border-top: 1px solid #616B6B;
  border-bottom: 1px solid #616B6B;
  color: $font-color;
  height: 70px;
  background-color: rgba(164, 163, 163, 0.1);
  justify-content: flex-start;
  font-family: $paragraph;

  &.not-clickable {
    cursor: unset;
  }

  &.dashboard {
    .source {
        color: #768181;
        margin: 5px 10px;
        display: flex;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
    }
  }
  .date {
    font-weight: 350;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 12px 0 -5px 10px;
  }

  .value {
    color: rgba(255, 255, 255, 0.4);
    text-overflow: ellipsis;
    width: 250px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    margin: 10px 0 -10px 35px;
  }

  .description-link {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .description-link:hover {
    color: #D0F31B;
  }
}

.no-top-border {
  border-top: none;
  background-color: #1C4148;
  cursor: unset;
}

.no-bottom-border {
  border-bottom: none;
  margin-bottom: 0;
  height: unset;
  background-color: #1C4148;
}

.log-search {
  &.table-row {
    padding: 0 42px;
  }
}
