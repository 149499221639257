@import '../../variables';
@import './VulnerabilitiesVariables';

.vulnerabilities-page-container {
  .beta-icon {
    width: 50px;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid $border;

    .vuln-toggle {
      height: 50px;
      align-items: center;
      margin-bottom: -30px;

      .tooltip {
        width: 500px;
        white-space: break-spaces;

        .tooltip-icon-small {
          width: 14px;
        }
      }
    }

    .page-title {
      width: 250px;
    }

    .vulns-time-filter {
      margin-bottom: -50px;
    }
  }

  .loader-container {
    &.vulns {
      min-height: 725px;
    }
  }

  .top-container {
    display: flex;
    max-height: fit-content;
    height: 19em;

    .loader-container {
      &.trends {
        width: 73%;
        margin-top: 5%;
        align-items: center;
        height: 19em;
      }

      &.metrics {
        width: 27%;
        margin-top: 5%;
      }
    }

    &.true {
      height: 4.8em;
      overflow: hidden;
      padding-bottom: 3em;
    }

    .data-list-container {
      width: 27%;
    }

    .graph-container {
      width: 73%;
      align-items: center;
      border-right: 1px solid $border;
      border-image: linear-gradient(to bottom, $border, rgba(0, 0, 0, 0)) 1 100%;
      padding-bottom: 2em;

      .legend-container {
        display: flex;
        width: 48em;
        position: relative;
        left: 3.4em;
        bottom: 2em;
      }

      .no-data {
        text-align: center;
        padding-top: 2em;
        font-size: 36px;
      }

      .title-and-legend {
        display: flex;
        margin-top: 30px;

        .expand-btn {
          padding-left: 34px;
          font-size: 39px;
          position: relative;
          bottom: 21px;
          color: $font-color;
          cursor: pointer;

          &.true {
            font-size: 21px;
            bottom: 5px;
            background-image: linear-gradient($border, transparent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 3em;
          }
        }

        .graph-title {
          white-space: nowrap;
          font-weight: 450;
          font-family: $heading;
          margin: 0 -200px -10px 10px;
          letter-spacing: 1.5px;
          position: relative;
          bottom: 5px;

          &.true {
            background-image: linear-gradient($border, transparent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        svg {
          margin-top: -5px;
        }
      }

      .vulns-line-chart {
        float: left
      }

      .trend-graph {
        margin-top: -55px;
        margin-left: 25px;
      }
    }
  }

  .vuln-pagination-container {
    display: flex;
  }

  .error-message {
    position: relative;
    right: -70px;
    top: 62px;
    color: $red;
    font-family: $paragraph;
  }

  .action-dropdown-container {
    position: absolute;
    left: calc(91.5% - 16px);
    margin-top: 70px;
    z-index: 40;

    .list-container {
      &:hover {
        font-weight: 500;
      }
    }

    .page-loading {
      &.relative-loader {
        margin-top: -25px;
      }
    }
  }

  .action-dropdown {
    position: absolute;
    z-index: 14;
    margin-top: -8px;

    .dropdown-title {
      &:hover {
        font-weight: 500;
      }
    }

    .list-item {
      white-space: nowrap;
    }

    &.disabled {
      .list-item {
        cursor: unset;
        color: $grey;

        &:hover {
          font-weight: 300;
        }
      }
    }
  }

  .dropdown-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    user-select: none;

    &.open {
      background-color: $secondary-bg;
      border: 1px solid $border;
      padding: 5px 10px 10px;
      margin-left: -10px;
      margin-top: -15px;
      border-radius: 2px;
      z-index: 25;
    }

    .page-loading {
      &.relative-loader {
        position: absolute;
        right: -86%;
      }
    }
  }

  .vulnerabilities {
    .table-header-container {
      z-index: 15;

      .td-value {
        width: unset;
        margin-right: unset;

        &.timestamp {
          min-width: $time;
        }

        &.Family {
          min-width: $family;
          width: $family;
        }

        &.Source {
          width: $source;
        }

        &.Severity {
          width: $severity;
        }

        &.Name {
          width: $name;
        }

        &.IP {
          width: $ip;
        }

        &.Port {
          width: $port;
        }

        &.CVE {
          width: $cve;
        }

        &.Report {
          width: $report;
        }

        &.Action {
          width: $actions;

          .tooltip-container {
            &.action {
              margin-left: 25px;
            }
          }
        }
      }
    }

    &.table-container {
      min-height: 500px;

      .table-row {
        &:hover {
          background-color: $secondary-bg;
          cursor: pointer;
        }

        .Family {
          width: $family;

          .tooltip-container {
            .text-contained {
              text-overflow: ellipsis;
              display: block;
              overflow: hidden;
            }
          }
        }

        .Severity {
          width: $severity;
        }

        .td-value {
          color: $font-color;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-right: unset;

          &.timestamp {
            min-width: $time;
            width: $time;
          }

          &.Family {
            width: $family;
            overflow: visible;
            text-overflow: ellipsis;
            white-space: nowrap;

            .text-contained {
              text-overflow: ellipsis;
              display: block;
              overflow: hidden;
              width: 150px;
            }

            .tooltip-container {
              overflow: visible;
              align: left;

              .tooltip {
                position: absolute;
                left: 12%;
                align: left;

                &.vuln-family {
                  width: fit-content;
                  align-items: left;
                }
              }
            }
          }

          &.Source {
            width: $source;
          }

          &.Report {
            width: $report;
          }

          &.Name {
            width: $name;
            overflow: visible;

            .title-container {
              display: flex;
              align-items: center;
              width: 100%;

              .tooltip-container {
                text-overflow: ellipsis;
                max-width: 90%;

                .tooltip {
                  position: absolute;

                  &.vuln-name {
                    width: fit-content;
                  }
                }
              }

              .text {
                text-overflow: ellipsis;
                max-width: 90%;
                display: block;
                overflow: hidden;
                white-space: nowrap;

                &.contained {
                  max-width: 100%;
                }

              }
            }
          }

          &.IP {
            width: $ip;
          }

          &.CVE {
            width: $cve;

            div {
              width: 80%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          &.Action {
            width: $actions;

            .list-item {
              &:hover {
                font-weight: 500;
              }
            }

            .title-tooltip {
              white-space: break-spaces;
              width: fit-content;
              margin-left: -20%;
            }

            .risk-mitigated-title {
              color: $font-color;
              font-family: $paragraph;
              position: relative;
              bottom: 7px;
              font-size: 15px;
            }

            .open-comment {
              margin-top: 15px;
              font-family: $paragraph;
              font-size: 13px;
              height: 15px;

              &:hover {
                font-weight: 500;
              }
            }
          }
        }

        .vulnerabilities-table-cell {
          width: 300px;

          &:nth-child(3) {
            margin-left: 100px;
          }

          &:last-child {
            margin-left: 150px;
          }

          .value {
            width: 100%;
          }
        }

        .value {
          color: $font-color;
        }

      }
    }
  }

  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 2%;
    font-size: 18px;
    color: $font-color;
  }
}

.line-filter {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
  border-top: 1px solid $border;
}

.vulnerabilities-table {
  margin-top: 50px;
  width: 82%;
  min-width: 1300px;
}

.vulnerabilities-filters {
  padding-top: 2px;
}

@media (max-width: 1680px) {
  .vulnerabilities-page-container {
    .action-dropdown-container {
      position: relative;
      left: unset;
      margin-left: 25px;
    }
  }
}
