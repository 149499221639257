@import '../../variables';

.button {
  background-color: $secondary-bg;
  color: #d4d4d4;
  padding: 3px 35px;
  font-size: 16.5px;
  border: 2px solid $border;
  cursor: pointer;
  margin-top: 52px;
  margin-bottom: 175px;
  font-family: $paragraph;

  &:hover {
    color: $blue;
  }

  &.disabled {
    border: 2px solid $grey;
    color: $grey;
    cursor: unset;

    &:hover {
      color: $grey;
    }
  }
}
