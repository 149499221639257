$severity: 6%;
$time: 8%;
$title: 32%;
$target: 22%;
$source: 12%;
$status: 13%;


$expanded-severity: 10%;
$expanded-time: 13%;
$expanded-title: 22%;
$expanded-target: 22%;
$expanded-source: 14%;
$expanded-status: 15%;