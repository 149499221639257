@import '../../variables';
@import './MobileVariables';

.mobile-vuln-modal {
  .modal-header {
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $darkgrey;
    width: 100%;
    font-weight: 600;
    position: relative;
    margin-top: 10px;
  }
  .loader-container {
    padding-top: 100px;
  }
  .modal-container {
    width: 950px;
    position: fixed;
    left: 49%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-data {
    min-height: 400px;
    max-height: 600px;
    height: auto;
    overflow: unset;
  }
  .modal-content {
    position: relative;
    width: 100%;
    display: flex;
    height: 390px;
    .mobile-vuln-list-container::-webkit-scrollbar {
      display: none;
    }
    .mobile-vuln-list-container {
      width: 300px;
      overflow-y: scroll;
      .mobile-vuln-list {
        margin-top: 20px;
        margin-left: 10px;
        padding-left: 0;
        list-style-type: none;
        white-space: nowrap;
        .mobile-vuln-item {
          padding-left: 10px;
          padding-top: 3px;
          padding-bottom: 3px;
          margin-bottom: 0;
          font-family: $paragraph;
          color: $grey;
          font-size: 17px;
          line-height: 1.47;
          width: 100%;
          white-space: break-spaces;
          cursor: pointer;
          &.selected {
            color: $white;
            background-color: rgba(255, 255, 255, 0.15);
            font-family: $roboto-bold;
          }
          .mobile-vuln-item-text {
            &.Critical {
              color: $red;
            }
            &.High {
              color: $red;
            }
            &.Medium {
              color: $orange;
            }
            &.Low {
              color: $yellow;
            }
          }
        }
      }
    }
    .mobile-vuln-info::-webkit-scrollbar {
      display: none;
    }
    .mobile-vuln-info {
      width: 70%;
      background-color: rgba(255, 255, 255, 0.15);
      overflow-y: scroll;
      table {
        border-collapse: separate; 
        border-spacing: 2px;
      }
      .mobile-vuln-table {
        margin-top: 20px;
        margin-left: 20px;
        padding-right: 30px;
        font-family: $paragraph;
        color: $lightgrey;
        font-size: 15px;
        line-height: 1.47;
        .vuln-column {
          display: flex;
          padding-bottom: 4px;
          &.recommendation {
            display: inline-block;
            padding-right: 10px;
          }
          &.phone_model_name {
            padding-bottom: 35px;
          }
          .mobile-vuln-column {
            color: $grey;
            padding-right: 4px;
            vertical-align: baseline;
            white-space: nowrap;
          }
          .clickable-link {
            cursor: pointer;
            color: inherit;
            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }
  }
}