@import '../../../variables';

.expanded-container {
  background-color: $secondary-bg;
  justify-content: space-between;
  color: $font-color;
  font-family: $paragraph;

  .vulnerability-data-table {
    width: 540px;
    padding-left: 50px;

    .threat-table {
      color: $font-color;
      word-break: break-word;
      width: fit-content;

      &.left {
        margin: 20px 0 25px 0;

        .no-value {
          color: $grey;
        }

        .Description {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;

          &.pointer {
            cursor: pointer;
          }
        }

        .Confidence {
          cursor: pointer;

          .tooltip-icon-small {
            width: 16px;
          }

          .confidence-value {
            padding-right: 7px;

            &.High {
              color: $green;
            }

            &.Medium {
              color: $yellow;
            }

            &.Low {
              color: $orange;
            }
          }
        }

        .expand-description {
          cursor: pointer;
          vertical-align: bottom;
          color: $grey;
          font-weight: bold;
          word-break: normal;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  .title {
    color: $font-color;
    font-size: 16.16px;
    line-height: 31px;
    font-weight: bold;
    text-align: right;
    margin-bottom: 0;
    padding-right: 10px;
    white-space: nowrap;
  }

  .label {
    text-align: right;
    color: $grey;
    white-space: nowrap;
    padding-right: 10px;
    font-weight: bold;
    vertical-align: top;
    width: 165px;
  }

  .cve-label {
    text-decoration: none;
    color: $grey;

    &:hover {
      color: $blue;
    }
  }

  .value {
    padding-left: 10px;
    max-width: 14vw;
    word-break: break-all;
    overflow-x: hidden;
  }

  .assets-and-details {
    margin-left: 5%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .dropdown-container {
      &.global-action-dropdown {
        width: 305px;
        margin-left: auto;
        padding-left: 17px;

        &.open {
          width: 140px;
          margin-right: 150px;

          &.disabled {
            .list-item {
              cursor: unset;
              color: $grey;

              &:hover {
                font-weight: 300;
              }
            }
          }
        }
      }
    }

    .assets-pagination {
      align-items: baseline;
      height: 55px;

      .results {
        font-size: 14px;
        margin-right: 20px;
      }
    }

    .pagination {
      display: flex;
      list-style: none;
      cursor: pointer;
      padding-left: 0;

      li {
        padding: 5px;
        color: $white;
        font-weight: 100;
      }

      .pagination-disabled {
        color: $darkgrey;
        cursor: auto;
      }

      li {
        color: $grey;
      }

      .pagination-active {
        color: $white;
        font-weight: bolder;
      }

      a {
        font-size: 14px;
        font-weight: bold;
        margin: 0 -2px;
      }
    }

    .tab-container {
      height: 45px;
      position: relative;
      bottom: -9px;
      margin: 20px 0 0 30px;

      .tab {
        padding: 10px 20px 5px 19px;
        border-color: $border;

        &.selected {
          background-color: $secondary-bg;
          font-weight: bold;
          border: 1px solid $border;
          border-top: none;
          border-bottom: none;
          color: $font-color;
        }
      }

      .tab-text {
        margin: -5px 0 -18px;
        height: 40px;
        font-size: 15px;
        letter-spacing: 1.76px;
        white-space: nowrap;

        .tab-total {
          font-size: 22px;
          font-weight: 200;
          margin-right: 6px;
        }
      }
    }

    .table-container {
      padding: 50px;
      border: 1px solid $border;
      margin-top: -5px;
      flex: 1;
      border-bottom: none;
      border-right: none;
    }
  }
}
