@import '../../variables';

.maturity-assessment {
  &.pagination-controls {
    .total-results {
      margin-bottom: -35px;
      padding-left: 10px;
      min-width: 250px;

      .pagination {
        margin-left: 30px;
      }
    }

    #form-search-bar {
      border-radius: 0;
      margin: 0 0 0 50%;
    }

    .MuiInputBase-root {
      background-color: $secondary-bg;
      border-radius: 0 !important;
      height: 27px;
    }

    .MuiInputBase-input {
      font-size: 13px;
      color: $font-color;
    }

    .MuiFormLabel-root {
      font-size: 13px;
      margin-top: -4px;
      color: $grey;

      .input-label {
        font-size: 10px;
      }
    }

    fieldset {
      border-color: $border !important;
    }

    .export-bau {
      margin: 0 -40px -40px 0;
    }
  }
}
