$case_number: 17%;
$created_date: 15%;
$priority: 13%;
$status: 20%;
$submitted_by: 20%;

$expanded_case_number: 15%;
$expanded_created_date: 15%;
$expanded_priority: 20%;
$expanded_status: 20%;
$expanded_submitted_by: 20%;