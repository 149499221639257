@import '../../variables';

.connections-page-container {
  .header-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;

    .page-title {
      width: 270px;
      padding: 10px 10px 10px 60px;
      letter-spacing: 3px;
    }

    .export-connections {
      margin-left: auto;
      width: 270px;
      padding: 38px 10px 10px 60px;
      color: #7D9395;
      font-family: $button;
      max-height: 40px;

      .export-text {
        cursor: pointer;
      }
      .carrot {
        font-size: 13px;
      }
      .export-loader {
        position: relative;
        bottom: 1em;
        right: 3em;
      }
    }
  }

  table {
    width: 100%;
  }

  .connections-row {
    min-height: 250px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 40px 50px 30px 60px;
    font-family: $heading;

    .connection-type {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 100px;
    }

    .error-text {
      white-space: nowrap;
      margin-top: 40px;
    }

    .is-connected {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: 1px;
      margin-bottom: 0;
      width: 88px;
    }

    .circle {
      display: flex;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-left: 20px;
      margin-top: 6px;
      background-color: $grey;

      &.green {
        background-color: #99E100;
      }

      &.red {
        background-color: $red;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.grey {
        background-color: $darkgrey;
      }
    }

    .service-icons {
      width: 350px;
      display: flex;
      margin: 25px 0 0 -25px;
      align-items: center;
      align-self: flex-start;

      .active-endpoint {
        display: flex;
        align-items: center;
        margin-right: 20px;
        &.clickable{
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .connection-data {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-bottom: 0;

      .pointer {
        cursor: pointer;
      }

      .link {
        &:hover {
          color: $yellow;
        }
      }

      .Contracted {
        margin-bottom: 15px;
      }
    }

    .count {
      text-align: right;
      font-size: 38px;
      font-weight: 300;
      width: 86px;
    }

    .display-values {
      display: flex;
      margin-left: 20px;
      width: 300px;
      font-size: 15px;
      margin-bottom: -15px;
    }

    .name {
      font-family: $paragraph;
      text-align: right;
      width: 200px;
      color: #7D9395;
      margin-right: 10px;
      text-overflow: ellipsis;
    }

    .value {
      font-family: $paragraph;
      text-align: left;
      color: $grey;
    }

    .chart-container {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      height: 200px;
      margin-bottom: 15px;
      
      &.disabled {
        color: $white;
        opacity: 0.5;
        height: 115px;
      }

      .legend-container {
        display: flex;
        margin: 0 0 -10px 15px;
        font-family: $paragraph;
        justify-content: flex-end;
      }

      line {
        display: none;
      }

      .disabled-chart {
        height: 20px;
        width: 35vw;
        margin-top: 70px;
        background: linear-gradient(180deg, rgba(31,54,60,1) 22%, rgba(3,36,40,1) 77%);
        border: 1px solid rgba(60, 77, 79, 1);
        border-radius: 1px;
        margin-right: 40px;
      }
    }

    .clickable-title{
      cursor: pointer;
      &:hover {
        color: $yellow;
      }
    }
  }

  .trend-container {
    position: relative;
    top: 8.2em;
    right: 21em;
    white-space: nowrap;
    width: 0;
    font-family: $paragraph;
    .trend-title {
      color: #7D9395;
      font-size: 14px;
    }
    .trend-text {
      display: contents;
      .trend-number {
        margin-left: 0;
        font-weight: 600;
      }
    }
  }

  .disabled {
    opacity: 0.4;
  }
}