@import '../../../variables';

.expanded-container {
  .assets-and-details {
    .dropdown-container {
      &.global-action-dropdown {
        &.vulnerabilities-tab {
          &.global-action-dropdown {
            margin-left: auto;
            width: 245px;

            &.open {
              width: 130px;
              margin-right: 110px;

              &.disabled {
                .list-item {
                  cursor: unset;
                  color: $grey;

                  &:hover {
                    font-weight: 300;
                  }
                }
              }
            }
          }
        }
      }
    }

    .vulnerabilities-tab-table-container {
      .severity-container {
        height: 20px;
        align-items: center;
        width: unset;
        margin-top: 3px;

        .severity-img {
          width: 18px;
        }

        .ls-table-cell {
          font-size: 12px;
          width: unset;
        }
      }

      &.assets {
        .table-body {
          color: $font-color;

          .table-row {
            cursor: default;
            border-bottom: 1px solid $border;
            padding: 0;
            height: 30px;
            background-color: transparent;

            &.patches {
              .title {
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 500px;
              }
            }

            &.headers {
              height: 23px;
              font-size: 13px;
              border-top: none;
              margin-top: -25px;
              cursor: pointer;

              &.vulnerabilities {
                .comment {
                  width: 5px;
                }
              }

              .caret {
                margin: 0 0 1px 5px;
              }
            }

            &.row-value {
              &.vulnerabilities {
                cursor: pointer;

                &:hover {
                  color: $blue
                }
              }
            }

            &.vulnerabilities {
              .vulnerability_id {
                width: 145px;
              }

              .severity {
                width: 105px;
                margin-left: auto;

                .critical {
                  width: 67px;
                }

                .high {
                  width: 55px;
                }

                .medium {
                  width: 70px;
                }

                .low {
                  width: 50px;
                }
              }

              .action_taken {
                width: 246px;

                .custom-checkbox {
                  margin-right: 5px;
                  cursor: pointer;
                }
              }

              .Action {
                width: 175px;
              }

              .description {
                width: 35vw;
                flex: 1;
                padding-right: 35px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              th {
                &.severity {
                  width: 107px;
                }
              }

              .severity-container {
                margin-right: 44px;
              }

              .description-tooltip {
                width: 95%;
              }

              .comment {
                width: 76px;

                .icon {
                  width: 20px;
                  height: 20px;
                  margin-top: 3px;
                  cursor: pointer;

                  &.add {
                    fill: $font-color;

                    &:hover {
                      fill: $blue;
                    }
                  }

                  &.edit {
                    stroke: $font-color;

                    &:hover {
                      stroke: $blue;
                    }
                  }
                }
              }
            }

            &.patches {
              th {
                &.severity {
                  width: 87px;
                }
              }

              .title-cell {
                width: 500px;
                padding-right: 35px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              }

              .action_taken {
                width: 193px;
              }

              .description {
                width: 450px;
                flex: 1;
                padding-right: 35px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .severity {
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .Source {
    text-transform: capitalize;
  }
}
