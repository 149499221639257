@import '../../variables';

.severity-container {
  display: flex;
  align-items: baseline;
  justify-content: left;
  position: relative;
  height: 25px;
  border-radius: 5px;
  border: solid 0.75px transparent;
  border-right-style: hidden;

  &.low {
    background-color: $title-bg;
    color: $low-font;
    width: 60px;

    .severity {
      margin-right: 7px;
      margin-left: 2px;
    }
  }

  &.medium {
    color: $medium;
    background-color: $title-bg;
    width: 92px;

    .severity {
      margin-right: 7px;
      margin-left: 2px;
    }
  }

  &.moderate {
    background-color: $grey;
    width: 97px;

    .severity {
      color: $medium;
      margin-right: 7px;
      margin-left: 2px;
    }
  }

  &.informational {
    background-color: $grey;
    width: 99px;

    .severity {
      color: $low-font !important;
      margin-right: 7px;
      margin-left: 5px;
    }
  }

  &.high {
    background-color: $high;
    width: 65px;
    color: $white;

    .severity {
      margin-right: 7px;
      margin-left: 2px;
    }
  }

  &.important {
    background-color: $orange;
    width: 97px;

    .severity {
      margin-right: 7px;
      margin-left: 2px;
    }
  }

  &.critical {
    background-color: $critical;
    color: $white;
    height: 27px;
    padding-top: 2px;
    width: 87px;

    .severity {
      margin-right: 7px;
      margin-left: 2px;
    }
  }

  .severity-img {
    margin-left: 5px;
    padding-right: 5px;
  }

  .severity {
    font-family: $paragraph;
    margin-left: 2px;
    font-size: 16px;
    letter-spacing: -0.04px;
  }
}