@import "../../../variables";

.add-account-dropdown {
  .dropdown-container {
    user-select: none;
    float: left;
    height: 30px;
    left: 40px;
    margin-right: 10px;
    border: #617b80 2px solid;
    background-color: $darkturquoise;
    z-index: 10;
    width: 210px;
    cursor: pointer;

    &.selected {
      border-bottom: $darkturquoise 1px solid;
    }

    .dropdown-label {
      position: relative;
      top: 2.5px;
      left: 9px;
      color: $lightgrey;
      font-size: 15px;

      &:hover {
        color: $white;
      }

      .down-arrow {
        position: relative;
        top: 2px;
        left: 1px;
      }
    }

    .dropdown-options {
      list-style-type: none;
      border: #617b80 2px solid;
      border-top: none;
      min-width: calc(100% + 4px);
      position: relative;
      left: -2px;
      padding: 4px 0 0 0;
      z-index: 10;
      background-color: $darkturquoise;

      li {
        margin: 0;
      }

      .dropdown-option {
        color: $lightgrey;
        position: relative;
        padding-left: 15px;
        cursor: pointer;
        height: 28px;
        line-height: 28px;

        &:hover {
          color: $white;
          background-color: $darkcyan;
        }

        &.selected {
          color: $white;
        }
      }

      .dropdown-option:last-child {
        margin-bottom: 1px;
      }
    }

    .dropdown-checkbox {
      position: relative;
      top: 2.5px;
      right: 7px;
      float: left;
      color: black;

      svg {
        color: $grey;
      }
    }
  }
}
