@import "../../../variables";

.vulnerability-scan-onboarding {
  .cell-content {
    color: $font-color;
    padding-right: 20px;

    &.clickable {
      color: $grey;
      cursor: pointer;

      &:hover {
        color: $yellow;
      }
    }

    &.scan_ips {
      max-width: 400px;

      .truncate {
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        margin-right: 5px;
      }

      .arrow-link {
        float: left;
      }
    }

    .tooltip-container {
      position: relative;
      float: right;
      right: 10px;
    }

    .table-icon {
      &.delete {
        position: relative;
        width: 28px;
        fill: $white;
        color: $white;
        right: 0;
        margin-left: 5px;
      }
    }
  }

  .confirm-cell {
    height: 5px;
    width: 200px;
    color: $font-color;
    margin-left: auto;
    margin-right: 0;

    .confirm-dialog {
      position: relative;
      top: 2px;
      right: 10px;
      float: right;
    }

    .confirm {
      padding-right: 0;
      margin-bottom: 2px;

      :hover {
        color: $grey;
      }
    }

    .cancel {
      padding-left: 5px;
      margin-bottom: 2px;

      :hover {
        color: $grey;
      }
    }

    .relative-loader {
      position: relative;
      left: 12px;
      bottom: 2px;
      height: 47px;
    }
  }

  .delete-tooltip {
    width: 50px;
  }

  .edit-tooltip {
    width: 40px;
  }
}
