@import '../../variables';
@import './MobileVariables';

.mobile-page-container {

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .page-title {
      width: 300px;
    }

    .tooltip-container {
      width: 250px;
    }
  }

  .mobile-content-container {
    .table-header-container {
      .td-value {
        &.last_seen {
          width: $time;
        }

        &.Email {
          width: $email;
        }

        &.os_type {
          width: $os;
          padding-left: 7px;
        }

        &.phone_model_name {
          width: $phonemodel;
        }

        &.os_version {
          width: $osversion;
          margin-right: 25px;
        }

        &.phone_model_version {
          width: $phonemodelversion;
        }

        &.Name {
          width: $name;
          margin-right: 6px;
        }

        &.status {
          width: $status;
          padding-left: 0.4%;
        }

        &.vulnerabilities_count {
          width: 14%;
          padding-left: 0.4%;
        }
      }
    }

    .no-results-text {
      display: flex;
      justify-content: left;
      margin-top: 2%;
      margin-left: 58px;
      font-size: 18px;
      color: $grey;
    }

    .gray-scale {
      opacity: 0.72;
      font-size: 16.16px;
      color: #788686;
      letter-spacing: -0.03px;
    }

    .table-row {
      .td-value {
        font-weight: 300;
        align-items: flex-start;
        color: rgba(255, 255, 255, 0.8);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 20.5px;
        margin-right: 20px;

        &.last_seen {
          display: flex;
          width: $time;
          margin-left: 0;
          margin-right: 8px;
        }

        &.Email {
          width: $email;
          margin-right: 0;
          margin-left: 2px;
        }

        .td-oversized {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 90%;
        }

        &.status {
          width: $status;
        }

        &.os_type {
          width: $os;
          &.icon {
            margin: 8px -5px 0 2px;
            &.Android {
              padding-left: 7px;
            }
            &.iOS {
              display: flex;
              align-items: flex-end;
            }
          }
        }

        &.phone_model {
          width: $phonemodel;
          padding-left: 13.5px;
        }

        &.os_version {
          width: $osversion;
          padding-left: 26px;
        }

        &.phone_model_version {
          width: $phonemodelversion;
          padding-left: 7px;
        }

        &.Name {
          width: $name;
          margin: 20px 0 0 5px;
        }

        &.Vulnerabilities {
          width: 12%;
          .vulnerabilities-value {
            width: fit-content;
            &.High {
              color: $red;
            }
            &.Medium {
              color: $orange;
            }
            &.Low {
              color: $yellow;
            }
            &.clickable {
              cursor: pointer;
            }
          }
        }
      }

      .left-row {
        width: 15%;
        display: flex;
        margin-right: 20px;

        &.severity {
          margin-right: 0;
        }

        .td-top {
          margin-top: 11px;
          font-weight: 350;
          font-size: 16.16px;
          color: #C0D0D0;
          letter-spacing: -0.03px;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.expanded {
            color: rgba(255, 255, 255, 0.6);
          }
        }

        .td-bottom {
          font-size: 16.16px;
          color: #8CA3A3;
          letter-spacing: -0.05px;
          margin: 5px 10px;
          display: flex;
          flex-wrap: nowrap;
          text-overflow: ellipsis;

          &.expanded {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .right-row {
        display: flex;
        margin: 10px 0 -5px 10px;

        .table-cell {
          display: flex;
          flex-direction: column;
        }

        .td-top {
          font-weight: 350;
          color: #8CA3A3;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 0;

          &.left {
            padding-left: 8em;
          }

          .highlight-text {
            color: #C0D0D0;
          }
        }

        .td-bottom {
          opacity: 0.61;
          font-size: 16.16px;
          color: #C0D0D0;
          letter-spacing: -0.03px;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          overflow-x: hidden;

          &.true {
            white-space: pre-line;
            overflow-wrap: break-word;
            padding-bottom: 5px;
          }

          &.expanded {
            opacity: 1;
          }
        }
      }
    }
  }
}

.mobile {
  cursor: unset;
}